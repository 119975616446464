'use client'
import { MissingInfoSteps } from '@/src/app/[lang]/missing-info/[uid]/(registration)/_types/missing-info-types'
import { RoundedButton } from '@/src/components/Button'
import Modal from '@/src/components/Modal/Modal'
import styles from '@/src/components/Steps/Steps.module.scss'
import { getSteps } from '@/src/components/Steps/utils'
import useRouter from '@/src/hooks/Router/useRouter'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import { usePathname } from '@/src/hooks/usePathname'
import { useGetSiteConfigQuery } from '@/src/services/cms'
import { useGetLimitsQuery, useGetPlayerQuery } from '@/src/services/player'
import { closeModal, showModal } from '@/src/store/slices/modalSlice'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const CheckOnboardingFinished = () => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const router = useRouter()
  const pathname = usePathname()
  const { isLoggedIn } = useAuthGuard()

  const { data: userDetails } = useGetPlayerQuery(undefined, {
    skip: !isLoggedIn,
  })
  const { data: limits } = useGetLimitsQuery(undefined, { skip: !isLoggedIn })
  const { data: site_config } = useGetSiteConfigQuery({ country: 'SWE' })

  const pageToOpen = useCallback(() => {
    const regex = new RegExp(
      '/missing-info|/login|/logged-out|/logout|/bonus|/my-account/edit/email|/responsible-gambling|/privacy-statement|/register|/pnp/swish|/pnp/swish/success|/pnp/swish/error|/pnp/deposit/success|/pnp|/my-account/edit/phone|/static/payment-methods|/static/privacy-notice',
      'g',
    )
    return !regex.test(pathname || '')
  }, [pathname])

  const steps: MissingInfoSteps = useMemo(() => {
    if (
      !userDetails ||
      !limits ||
      !site_config ||
      userDetails.error ||
      limits.error
    )
      return ['/']
    return getSteps(userDetails, limits, site_config)
  }, [userDetails, limits, site_config])

  useEffect(() => {
    if (isLoggedIn && pageToOpen()) {
      if (!(steps.length === 1 && steps[0] === '/')) {
        dispatch(showModal('finish-onboarding-modal'))
      }
    }
  }, [
    userDetails,
    limits,
    site_config,
    dispatch,
    pageToOpen,
    isLoggedIn,
    steps,
    pathname,
  ])

  const continueOnboarding = async () => {
    dispatch(closeModal('finish-onboarding-modal'))
    router.push('/missing-info')
  }

  return (
    <Modal
      className={styles.verifModalContent}
      targetModal='finish-onboarding-modal'
      allowClickOutside={false}
      allowClose={false}
      heading={t('check.onboarding.finished.header')}
      textSection={t('check.onboarding.finished.description')}
    >
      <RoundedButton
        className={styles.depositConfirmModalModify}
        size='large'
        fullWidth
        onClick={continueOnboarding}
      >
        {t('onboarding.continue.button')}
      </RoundedButton>
    </Modal>
  )
}

export default CheckOnboardingFinished
